import { getYear } from "date-fns";
import i18next from "i18next";
import { range } from "lodash";
import moment from "moment";
import { getLang } from "../constants";
import { AU_CONSTANT } from "./AUConstants";
import { US_CONSTANT } from "./USConstants";
import { NZ_CONSTANT } from "./NZConstants";
import { CA_CONSTANT } from "./CAConstants";
import { GB_CONSTANT } from "./GBConstants";

export const getAddDays = (days) => {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result;
};

export const getDiffDays = (startDate, endDate) => {
    const start = moment(startDate, "YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD");
    return moment.duration(end.diff(start)).asDays();
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeFirstLetterForEachWord = (string) =>
    string.toLowerCase().replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });

export const windowScroll = (id) => {
    window.scrollTo({
        top: document.getElementById(id).getBoundingClientRect().top + window.scrollY - (80 + 68),
        behavior: "smooth"
    });
};

export const yearsDropdown = range(1900, getYear(new Date()) + 1, 1);
export const monthsDropdown = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const formatCurrency = (amount) => amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

export const formatPhoneNumber = (phoneNumber) => {
    return getSiteValueByLang("formatPhoneNumber")(phoneNumber)
};

export const getScrollPosition = (className) => {
    const rdrRoundEdgeElement = document.querySelector(className);
    const rect = rdrRoundEdgeElement.getBoundingClientRect();
    const distanceFromTop = rect.top + window.scrollY - 200;
    return distanceFromTop;
};

export const updateSiteLang = () => {
    const lang = getLang();
    i18next.changeLanguage(lang);
};

export const siteValueConstant = {
    'en-US': US_CONSTANT,
    'en-AU': AU_CONSTANT,
    'en-NZ': NZ_CONSTANT,
    'en-CA': CA_CONSTANT,
    'en-GB': GB_CONSTANT,
};

export const getSiteValueByLang = (key, value) => {
    const lang = getLang();
    return value ? siteValueConstant[lang][key](value) : siteValueConstant[lang][key];
};

export const getPreviousResignedSearchStatus = () => {
    return localStorage.getItem("plt_display_redesigned_search");
};

export const setNewDesignStatusLocally = (status) => {
    return localStorage.setItem("plt_display_redesigned_search", status);
};
export const padWithZero = (number) => {
    const numString = number.toString();
    if (numString.length === 1) {
        return "0" + numString;
    }
    return numString;
};
