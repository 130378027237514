import { Box, Grid } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { DisplayText, Text } from "../../../components/common/typography/Style";
import { _url } from "../../../config/utils";
import { ColorTheme } from "../../../styles/color";
import { getSiteValueByLang } from "../../../utils/SiteUtils";
import { BlurDiv, TicketContainer } from "./style";
import { randomiseCustomerList } from "./util";
import { TrustpilotWidget } from "../../../components/common/TrustpilotWidget";

const HomeCustomerReview = () => {
    const { mobileView } = useGetWindowSize();
    const [customerList, setCustomerList] = useState(randomiseCustomerList(getSiteValueByLang("recentBookings")));
    let interval = null;

    return (
        <Grid container mt={"56px"}>
            <Grid item xs={12}>
                <img
                    src={
                        mobileView
                            ? _url("assets/redesign/icons/happy_customers_mobile.png")
                            : _url("assets/redesign/icons/happy_customers.png")
                    }
                    style={{ width: "100%" }}
                />
            </Grid>
            <Grid item xs={12} mt={"24px"}>
                <DisplayText textsize={mobileView ? "24px" : ""} align="center" size={"md"} textweight={"bold"}>
                    Our Happy Customers
                </DisplayText>
            </Grid>

            <Grid item xs={12} mt={"40px"} mb={["0px", "0px", "80px"]}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={10} md={12}>
                        <TrustpilotWidget template="carousel" style-width="100%" stars="5" review-languages="en" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} mt={["24px", "24px", "56px"]}>
                <Grid container justifyContent={"center"}>
                    <Grid item maxWidth={"1140px"}>
                        <Grid
                            container
                            alignItems={"center"}
                            direction={["column", "column", "row"]}
                            columnSpacing={"20px"}
                            flexWrap={"nowrap"}
                        >
                            <Grid item xs={5}>
                                <DisplayText
                                    align="center"
                                    textsize={mobileView ? "24px" : "36px"}
                                    textheight={"42px"}
                                    size={"md"}
                                    textweight={"bold"}
                                >
                                    Join our growing community!
                                </DisplayText>
                                <Text
                                    mt={"16px"}
                                    align={"center"}
                                    size={"lg"}
                                    textsize={mobileView ? "14px" : undefined}
                                    textweight={"regular"}
                                    textcolor={ColorTheme.grayNeutral[500]}
                                >
                                    Check out where customers are travelling
                                </Text>
                            </Grid>
                            <Grid item xs={7} mt={["32px", "32px", ""]}>
                                <Box
                                    display={"flex"}
                                    justifyContent={["center", "center", "initial"]}
                                    flexWrap={"wrap"}
                                    gap={"20px"}
                                    overflow={"auto"}
                                    key={JSON.stringify(customerList)}
                                    height={mobileView ? "420px" : "500px"}
                                >
                                    {customerList.map((item, index) => (
                                        // <Fade in={!hidefade} timeout={4000} key={`recent-boooing-${item.id}`}>
                                        <TicketContainer
                                            key={`recent-boooing-${item.id}`}
                                            ml={[
                                                index % 2 === 0 ? "" : "40px",
                                                index % 2 === 0 ? "" : "40px",
                                                (index + 1) % 3 === 0 ? "40px" : ""
                                            ]}
                                        >
                                            <Grid
                                                container
                                                display={"flex"}
                                                flexDirection={"row"}
                                                justifyContent={"flex-start"}
                                                position={"relative"}
                                                left={"20px"}
                                                top={"33%"}
                                                alignItems={"center"}
                                                gap={"25px"}
                                                flexWrap={"nowrap"}
                                            >
                                                <Grid item width={"20%"}>
                                                    <div
                                                        style={{
                                                            width: 48,
                                                            height: 48,
                                                            position: "relative",
                                                            overflow: "hidden",
                                                            borderRadius: "50%",
                                                            border: "8px solid #F3f4f6"
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                display: "inline",
                                                                margin: "0 auto",
                                                                height: "100%",
                                                                width: "auto"
                                                            }}
                                                            src={_url(
                                                                `assets/redesign/icons/flag_icon/${item.countryCode}.png`
                                                            )}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item width={"70%"} pr={"15px"}>
                                                    <Text pr={"20px"} size={"sm"} textweight={"semiBold"}>
                                                        {item.customerName} booked a trip to {item.destination} for{" "}
                                                        {moment(item.arrivalDate).format("MMM")}
                                                    </Text>
                                                </Grid>
                                            </Grid>
                                        </TicketContainer>
                                        // </Fade>
                                    ))}
                                </Box>
                                <BlurDiv bottom={"96px"} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomeCustomerReview;
