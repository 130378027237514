import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { emptyPaymentPlan } from "../../../redux/modules/paymentPlan";
import { emptySearchResults } from "../../../redux/modules/search";
import { ColorTheme } from "../../../styles/color";
import { triggerHomePageLoadedEvent } from "../../../utils/events";
import HomeCustomerReview from "./HomeCustomerReview";
import HomeFeature from "./HomeFeature";
import HomePopularDestination from "./HomePopularDestination";
import HomepageHeader from "./HomepageHeader";
import { useHome } from "./hook";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { Promotions, Promotion } from "../promotions/Promotion";
import { IS_AU_SITE, IS_USA_SITE } from "../../../constants";

const Homepage = () => {
    const { selectedLocation, selectedTab, setSelectedLocation, setSelectedTab, dispatch, location } = useHome();
    const searchParams = new URLSearchParams(location.search);
    const { mobileView } = useGetWindowSize();

    useEffect(() => {
        triggerHomePageLoadedEvent();
        searchParams.get("tab") === "flights" && setSelectedTab(1);
        dispatch(emptySearchResults());
        // empties the payment plan data on redux store to prevent a bug where wrong departure and payment start date data being used in the "Review” page even after the customer has selected a different search parameter in the homepage.
        dispatch(emptyPaymentPlan());
    }, [dispatch, location.pathname, setSelectedTab]);

    return (
        <Grid container minHeight={"100vh"}>
            <Promotions>
                <Promotion
                    promotionName={"Black Friday 2024 - US"}
                    condition={() => IS_USA_SITE}
                    startDateTimeInclusive={"2024-11-21T08:00:00-05:00"}
                    endDateTimeExclusive={"2024-12-03T00:00:00-05:00"}
                    banner1920={"assets/redesign/promotions/bfcm/1-banner-1920.webp"}
                    banner1440={"assets/redesign/promotions/bfcm/1-banner-1440.webp"}
                    banner390={"assets/redesign/promotions/bfcm/1-banner-390.webp"}
                    barBackgroundColor={"#101010"}
                    bar1920={"assets/redesign/promotions/bfcm/1-bar-1920.webp"}
                    bar1440={"assets/redesign/promotions/bfcm/1-bar-1440.webp"}
                    bar390={"assets/redesign/promotions/bfcm/1-bar-390.webp"}
                />
                <Promotion
                    promotionName={"Black Friday 2024 - AU#1"}
                    condition={() => IS_AU_SITE}
                    startDateTimeInclusive={"2024-11-21T08:00:00+11:00"}
                    endDateTimeExclusive={"2024-11-27T00:00:00+11:00"}
                    banner1920={"assets/redesign/promotions/bfcm/1-banner-1920.webp"}
                    banner1440={"assets/redesign/promotions/bfcm/1-banner-1440.webp"}
                    banner390={"assets/redesign/promotions/bfcm/1-banner-390.webp"}
                    barBackgroundColor={"#101010"}
                    bar1920={"assets/redesign/promotions/bfcm/1-bar-1920.webp"}
                    bar1440={"assets/redesign/promotions/bfcm/1-bar-1440.webp"}
                    bar390={"assets/redesign/promotions/bfcm/1-bar-390.webp"}
                />
                <Promotion
                    promotionName={"Black Friday 2024 - AU#2"}
                    condition={() => IS_AU_SITE}
                    startDateTimeInclusive={"2024-11-27T00:00:00+11:00"}
                    endDateTimeExclusive={"2024-11-29T00:00:00+11:00"}
                    banner1920={"assets/redesign/promotions/bfcm/2-banner-1920.webp"}
                    banner1440={"assets/redesign/promotions/bfcm/2-banner-1440.webp"}
                    banner390={"assets/redesign/promotions/bfcm/2-banner-390.webp"}
                    bannerLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=homepage_banner&utm_campaign=BFCM"}
                    barBackgroundColor={"#101010"}
                    bar1920={"assets/redesign/promotions/bfcm/2-bar-1920.webp"}
                    bar1440={"assets/redesign/promotions/bfcm/2-bar-1440.webp"}
                    bar390={"assets/redesign/promotions/bfcm/2-bar-390.webp"}
                    barLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=announcement_bar&utm_campaign=BFCM"}
                />
                <Promotion
                    promotionName={"Black Friday 2024 - AU#3"}
                    condition={() => IS_AU_SITE}
                    startDateTimeInclusive={"2024-11-29T00:00:00+11:00"}
                    endDateTimeExclusive={"2024-12-03T00:00:00+11:00"}
                    banner1920={"assets/redesign/promotions/bfcm/3-banner-1920.webp"}
                    banner1440={"assets/redesign/promotions/bfcm/3-banner-1440.webp"}
                    banner390={"assets/redesign/promotions/bfcm/3-banner-390.webp"}
                    bannerLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=homepage_banner&utm_campaign=BFCM"}
                    barBackgroundColor={"#101010"}
                    bar1920={"assets/redesign/promotions/bfcm/2-bar-1920.webp"}
                    bar1440={"assets/redesign/promotions/bfcm/2-bar-1440.webp"}
                    bar390={"assets/redesign/promotions/bfcm/2-bar-390.webp"}
                    barLink={"https://offers.paylatertravel.com.au/?utm_source=website&utm_medium=announcement_bar&utm_campaign=BFCM"}
                />
            </Promotions>
            <Grid item xs={12} testid="test100">
                <HomepageHeader
                    selectedLocation={selectedLocation}
                    selectedTab={selectedTab}
                    setSelectedLocation={setSelectedLocation}
                    setSelectedTab={setSelectedTab}
                />
            </Grid>
            <Grid item xs={12} bgcolor={ColorTheme.base.white}>
                <Grid container justifyContent={"center"}>
                    <Grid item px={["20px", "20px", "0px"]} maxWidth={"1140px"}>
                        <HomeFeature />
                    </Grid>
                </Grid>
            </Grid>
            <div id="promotion-banner" />{" "}
            {/* BANNER CODE -- Filled by the `Promotion` component; `Promotion`s to be added above */}
            <Grid item xs={12}>
                <HomeCustomerReview />
            </Grid>
            <Grid item xs={12} pb={"160px"} bgcolor={ColorTheme.base.white}>
                <Grid container justifyContent={"center"}>
                    <Grid item pt={"80px"} px={["20px", "20px", "0px"]} maxWidth={["100%", "100%", "1140px"]}>
                        <HomePopularDestination />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Homepage;
