import { _formatMoney } from "../config/utils";
import { AU_RECENT_BOOKINGS, AU_SITE_POPULAR_ARRIVAL, AU_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { auPopularDestination } from "../containers/redesign/homepage/constant";

export const AU_CONSTANT = {
    stripePublicKey: process.env.REACT_APP_STRIPE_PK_AU,
    placesAutocompletedLocation: {
        lat: -34,
        lng: 151
    },
    addressCountry: "Australia",
    billingAddressSelectStates: "US",
    getPaymentFrequency: "Fortnightly",
    passportOrigin: "AU",
    bookingsEmail: "support@paylatertravel.com.au",
    phoneInputWrapper: "au",
    trustpilot: {
        locale: "en-AU",
        businessUnitID: "638e548b607befcb07d4c19d",
        link: "https://www.trustpilot.com/review/paylatertravel.com.au",
    },
    homeflightMockup: "assets/images/paylaterflightsweb.png",
    countryCode: "28",
    currencyCode: "AUD",
    toVisualCurrency: ({ amount }) => {
        return `AU$ ${_formatMoney(amount / 100)}`;
    },
    supportMail: "support@paylatertravel.com.au",
    madeWithLove: "Made with love in Sydney Australia.",
    privacyPolicy: "https://paylatertravel.notion.site/PLT-Privacy-Policy-AU-1206a5884d288014bc75cb2990c475ce",
    formikMuiDateFormat: "DD/MM/YYYY",
    cardRental: "https://www.driveaway.com.au/affiliates/1014025",
    gaMeasuremntId: "G-Q2KV8PSEVF",
    popularDestination: auPopularDestination,
    popularDepartureDestination: AU_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: AU_SITE_POPULAR_ARRIVAL,
    recentBookings: AU_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/Australia-Customer-Terms-and-Conditions-b85da50e066645008bbc659e74e918df",
    dateOfBirthExample: "30/12/1997",
    formatPhoneNumber: (phoneNumber) => {
        const phoneFormat = phoneNumber && phoneNumber.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
        return !phoneFormat[2]
            ? phoneFormat[1]
            : `+${phoneFormat[1]} ${phoneFormat[2]}${phoneFormat[3] ? ` ${phoneFormat[3]} ${phoneFormat[4]}` : ""}`;
    },
    footerBrands: "/assets/redesign/icons/footer_stripe.svg"
};
