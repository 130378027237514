import { _formatMoney } from "../config/utils";
import { GB_RECENT_BOOKINGS, GB_SITE_POPULAR_ARRIVAL, GB_SITE_POPULAR_DEPARTURE } from "../constants/Options";
import { gbPopularDestination } from "../containers/redesign/homepage/constant";

export const GB_CONSTANT = {
    stripePublicKey: process.env.REACT_APP_STRIPE_PK_US,
    placesAutocompletedLocation: {
        lat: 52.1323712,
        lng: -0.6451301
    },
    addressCountry: "United Kingdom",
    billingAddressSelectStates: "GB",
    getPaymentFrequency: "Bi-weekly",
    passportOrigin: "GB",
    bookingsEmail: "support@paylatertravel.co.uk",
    phoneInputWrapper: "gb",
    trustpilot: {
        locale: "en-GB",
        businessUnitID: "6023a4aac0f73e00011dcb3e",
        link: "https://www.trustpilot.com/review/paylatertravel.com",
    },
    homeflightMockup: "assets/images/paylaterflightswebusa.png",
    countryCode: "44",
    currencyCode: "GBP",
    toVisualCurrency: ({ amount }) => {
        return `£ ${_formatMoney(amount / 100)}`;
    },
    supportMail: "support@paylatertravel.co.uk",
    madeWithLove: "",
    privacyPolicy: "https://paylatertravel.notion.site/PLT-Privacy-Policy-USA-1206a5884d28806abbb3e97c756489c9",
    formikMuiDateFormat: "DD/MM/YYYY",
    cardRental: "https://www.gotrentalcars.com/us-label/?agence_id=5890",
    gaMeasuremntId: "G-P3RJJR6Z0Z",
    popularDestination: gbPopularDestination,
    popularDepartureDestination: GB_SITE_POPULAR_DEPARTURE,
    popularArrivalDestination: GB_SITE_POPULAR_ARRIVAL,
    recentBookings: GB_RECENT_BOOKINGS,
    termsAndConditions: "https://paylatertravel.notion.site/United-States-Customer-Terms-and-Conditions-7e43ec2d4cd94c2988905a8a5d54e595",
    dateOfBirthExample: "1997-12-30",
    formatPhoneNumber: phoneNumber => phoneNumber,
    footerBrands: "/assets/redesign/icons/footer_stripe-us.svg"
};
