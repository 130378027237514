export const usaPopularDestination = [
    {
        id: 1,
        title: "Las Vegas",
        image: "assets/redesign/lasVegas.png"
    },
    {
        id: 2,
        title: "Orlando",
        image: "assets/redesign/orlando.png"
    },
    {
        id: 3,
        title: "Miami",
        image: "assets/redesign/miami.png"
    },
    {
        id: 4,
        title: "New York",
        image: "assets/redesign/nyc.png"
    },
    {
        id: 5,
        title: "San Juan",
        image: "assets/redesign/sanjuan.png"
    }
];

export const auPopularDestination = [
    {
        id: 1,
        title: "Bali",
        image: "assets/redesign/denpasar.png"
    },
    {
        id: 2,
        title: "Fiji",
        image: "assets/redesign/nadi.png"
    },
    {
        id: 3,
        title: "Auckland",
        image: "assets/redesign/auckland.png"
    },
    {
        id: 4,
        title: "Samoa",
        image: "assets/redesign/apia.png"
    },
    {
        id: 5,
        title: "Melbourne",
        image: "assets/redesign/melbourne.png"
    }
];

export const caPopularDestination = [
    {
        id: 1,
        title: "New York",
        image: "assets/redesign/nyc.png"
    },
    {
        id: 2,
        title: "London",
        image: "assets/redesign/london.png"
    },
    {
        id: 3,
        title: "Mexico",
        image: "assets/redesign/mexico.png"
    },
    {
        id: 4,
        title: "Montreal",
        image: "assets/redesign/montreal.png"
    },
    {
        id: 5,
        title: "Cuba",
        image: "assets/redesign/cuba.png"
    }
];

export const gbPopularDestination = [
    {
        id: 1,
        title: "New York",
        image: "assets/redesign/nyc.png"
    },
    {
        id: 2,
        title: "Barcelona",
        image: "assets/redesign/barcelona.png"
    },
    {
        id: 3,
        title: "Mexico",
        image: "assets/redesign/mexico.png"
    },
    {
        id: 4,
        title: "Paris",
        image: "assets/redesign/paris.png"
    },
    {
        id: 5,
        title: "Cuba",
        image: "assets/redesign/cuba.png"
    }
];
