import { getSiteValueByLang } from "../../utils/SiteUtils";
import { useEffect, useRef } from "react";

const templates = {
    horizontal: "5406e65db0d04a09e042d5fc",
    "micro-star": "5419b732fbfb950b10de65e5",
    "micro-combo": "5419b6ffb0d04a076446a9af",
    "micro-review-count": "5419b6a8b0d04a076446a9ad",
    "mini": "53aa8807dec7e10d38f59f32",
    carousel: "53aa8912dec7e10d38f59f36",
    "mini-carousel": "539ad0ffdec7e10e686debd7",
    "starter": "5613c9cde69ddc09340c6beb",
};

export const TrustpilotWidget = ({ template, ...dataAttrs }) => {
    const settings = getSiteValueByLang("trustpilot");
    const templateID = templates[template];
    const ref = useRef(null);
    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    if (!templateID) {
        return (
            <div
                ref={ref}
                style={{
                    backgroundColor: "red",
                    color: "pink",
                    textAlign: "center",
                    padding: "10px",
                    border: "1px solid maroon",
                    borderRadius: "3px"
                }}
            >
                <span style={{ fontWeight: "bold" }}>TrustPilot Widget:</span> template ID not found for '{template}'!
            </div>
        );
    }

    const data = Object.entries(dataAttrs).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [`data-${key}`]: value
        }),
        {
            "data-theme": "light"
        }
    );

    return (
        <div
            ref={ref}
            className="trustpilot-widget"
            data-locale={settings.locale}
            data-template-id={templateID}
            data-businessunit-id={settings.businessUnitID}
            {...data}
        >
            <a href={settings.link} target="_blank" rel="noopener noreferrer">
                Trustpilot
            </a>
        </div>
    );
};
