const domainToLangMapping = {
    // en-AU
    'localhost.au': 'en-AU',
    'www-au.staging.paylatertravel.dev': 'en-AU',
    'www.paylatertravel.com.au': 'en-AU',
    'paylatertravel.com.au': 'en-AU',

    // en-US
    "www.paylatertravel.com": 'en-US',
    "www.paylatertravel.dev": 'en-US',
    "www.staging.paylatertravel.dev": 'en-US',
    "www.preprod.paylatertravel.dev": 'en-US',
    "localhost": 'en-US',
    "localhost.us": 'en-US',

    // en-NZ
    "www.paylatertravel.co.nz": 'en-NZ',
    "www-nz.staging.paylatertravel.dev": 'en-NZ',
    "localhost.nz": 'en-NZ',

    // en-CA
    "localhost.ca": 'en-CA',
    "www-ca.staging.paylatertravel.dev": 'en-CA',
    "www.paylatertravel.ca": 'en-CA',

    // en-GB
    "localhost.gb": 'en-GB',
    "www-uk.staging.paylatertravel.dev": 'en-GB',
    // "paylatertravel.co.uk": 'en-GB', -- Uncomment these lines to release UK to prod
    // "www.paylatertravel.co.uk": 'en-GB', -- Uncomment these lines to release UK to prod
};
export const getLang = () => {
    const lang = domainToLangMapping[window.location.hostname];
    if (!lang) {
        throw new Error("Language not found for domain: " + window.location.hostname);
    }
    return lang;
};

const isSite = locale => {
    return domainToLangMapping[window.location.hostname] === locale;
}

export const IS_AU_SITE = isSite('en-AU')
export const IS_USA_SITE = isSite('en-US')
export const IS_NZ_SITE = isSite('en-NZ')

export const FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY = 14;
export const TRAVEL_INSURANCE_LINK_US =
    "https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d";
export const TRAVEL_INSURANCE_LINK_AU =
    "https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d";
export const CHANGES_ELIGIBILITY_DATE = "2021-11-12";
export const CANCELLATIONS_ELIGIBILITY_DATE = "2021-12-03";
export const MAX_HOTEL_LIST = 30;
export const STAYS_MIN_DAYS_BEFORE_BOOKING = 14;
export const LOGIN_URL = "plt-web/auth/LOGIN";
export const STAYS_2_0 = true;
export const STAYS_2_0_API = false;
export const EDIT_TRAVELLERS = false;
export const WISTIA_EMBED_ID = "96qxzhtajy";
export const STAYS_NEW_STICKER = true;
export const BOOKING_RECOMMENDED_STAYS_LIST = true;
export const MUI_LICENSE_KEY =
    "922af3b2e61bd63dbac6098f405590c3Tz02MTM0NyxFPTE3MDk3MDQ4MTcxNDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
export const FLIGHT_BEST_SORT = "BEST";
export const FLIGHT_CHEAPEST_SORT = "PRICE_LOW_TO_HIGH";
export const FLIGHT_DEPOSIT_SORT = "DEPOSIT_LOW_TO_HIGH";
export const FLIGHT_WEEKLY_SORT = "WEEKLY_LOW_TO_HIGH";
export const SHOW_CABIN_CLASS = true;
export const FEATURE_FLAG_MOBILE_NEW_DATEPICKER = false;
export const SHOW_FLIGHT_SEARCH_NEW_DESIGN = true;
export const NUMBER_OF_SECONDS_IN_WEEK = 604800;
export const BOOKING_PER_PAGE = 5;
